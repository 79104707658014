<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div class="mh-100vh bg-white content-page" v-else>
      <RegisterFormComponent
        :form="form"
        :v="$v"
        @checkForm="checkForm"
        title="สมัครสมาชิก"
        :isDisplayTelephone="false"
        :disableBtn="disableBtn"
        :customField="customField"
        :questionList="questionList"
        :chkBirthDay="chkBirthDay"
        :is_privacy_policy1="is_privacy_policy1"
      />
      <ModalError ref="modalError" :text="successMessage" />
    </div>
  </div>
</template>

<script>
import ModalError from "@/components/alert-modal/ModalError";
import RegisterFormComponent from "@/components/register/RegisterFormComponent";
import {
  required,
  helpers,
  email,
  minLength,
  requiredIf
} from "vuelidate/lib/validators";
import OtherLoading from "@/components/other/OtherLoading";
const alpha = helpers.regex("alpha", /^[ก-๙a-zA-Z]*$/);
export default {
  components: {
    ModalError,
    RegisterFormComponent,
    OtherLoading
  },
  data() {
    return {
      form: {
        registerTypeId: 2,
        SocialProvider: "line",
        SocialId: "",
        firstname: "",
        lastname: "",
        email: "",
        telephone: this.$route.query.tel,
        birthday: "",
        firstname_en: "",
        lastname_en: "",
        display_name: "",
        password: "",
        branch_id: "0",
        home_address: "",
        alley: "",
        town: "",
        road: "",
        subdistrict: "",
        district: "",
        province: "",
        zip_code: "",
        gender: "N/A",
        t_datetime: "",
        line_id: "",
        the_one_card_member: "",
        line_ref: "",
        accept_terms_and_conditions: 1,
        birthday_day: "",
        birthday_month: "",
        birthday_year: "",
        is_consent: 0,
        is_privacy_policy: 0
      },
      successMessage: "",
      errorBirthDate: "",
      validateBirthDate: false,
      isLoading: false,
      disableBtn: false,
      customField: [],
      questionList: [],
      user_guid: "",
      chkBirthDay: process.env.VUE_APP_BIRTHDAY,
      is_privacy_policy1: 0
    };
  },
  beforeCreate: async function () {
    this.$liff.init({ liffId: this.$liff_ID_Login });
  },
  async created() {
    if (!this.$cookies.get("hourglass_register_success")) {
      this.$liff.ready
        .then(() => {
          if (!this.$liff.isLoggedIn()) {
            this.$liff.login().then(() => {
              this.$liff.getProfile().then(profile => {
                this.lineProfile = profile;
                this.form.SocialId = profile.userId;
              });
            });
          } else {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.form.SocialId = profile.userId;
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
      await this.getCustomField();
    } else {
      this.$router.push("/profile");
    }
  },
  validations() {
    return {
      form: {
        firstname: { required, alpha },
        lastname: { required, alpha },
        email: { email },
        telephone: { required, minLength: minLength(9) },
        birthday_day: { required },
        birthday_month: { required },
        birthday_year: {
          required: requiredIf(function (item) {
            return this.chkBirthDay === "Diageo"
          }),
          minLength: minLength(4)
        }
      },
      questionList: {
        $each: {
          answer_list: {
            $each: {
              answer: {
                required: requiredIf(function (item) {
                  return !item.answer && item.required === 1;
                })
              }
            }
          }
        }
      }
    };
  },
  methods: {
    async getCustomField() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/User/custom_field`)
        .then(async data => {
          this.isLoading = false;
          if (data.result === 1) {
            this.customField = data.detail;
            for (const field of this.customField) {
              if (field.choice_list.length > 0) {
                for (const choice of field.choice_list) {
                  if (choice.is_default) {
                    this.questionList.push({
                      question_id: field.question_id,
                      field_type_id: field.field_type_id,
                      answer_list: [
                        {
                          answer_id: choice.choice_id,
                          answer: choice.choice,
                          required: field.required
                        }
                      ]
                    });
                  }
                }
              } else {
                this.questionList.push({
                  question_id: field.question_id,
                  field_type_id: field.field_type_id,
                  answer_list: [
                    {
                      answer_id: 0,
                      answer: "",
                      required: field.required
                    }
                  ]
                });
              }
            }
          }
        });
    },
    checkForm: async function (val) {
      this.form = val.form;
      if (this.form.is_consent) {
        this.form.is_consent = 1;
      } else {
        this.form.is_consent = 0;
      }
      this.$v.form.$touch();
      this.$v.questionList.$touch();
      if (this.$v.questionList.$error) {
        return;
      }
      if (this.$v.form.$error) {
        return;
      }
      if (!val.chk18Birthday) {
        return;
      }
      this.isLoading = true;
      if (!this.form.birthday_year) {
        this.form.birthday_year = null;
      }
      await this.$axios
        .post(`${process.env.VUE_APP_API}/api/v1/user/Register`, this.form)
        .then(async data => {
          if (data.result == 1) {
            this.$cookies.set("hourglass_token", data.detail.token);
            this.user_guid = data.detail.id;
            if (this.customField.length > 0) {
              await this.updateCustomField();
            } else {
              this.isLoading = false;
              this.$cookies.set("popupModalRegister", true);
              this.$router.push("/profile");
            }
          } else {
            this.disableBtn = false;
            if (data.detail[0]) {
              this.successMessage = data.detail[0];
            } else {
              this.successMessage = data.message;
            }
            this.$refs.modalError.show();
          }
        });
    },
    async updateCustomField() {
      let body = {
        user_guid: this.user_guid,
        admin_user_guid: this.user_guid,
        question_list: this.questionList
      };
      await this.$axios
        .put(
          `${process.env.VUE_APP_API}/api/v1/User/update_custom_answer`,
          body
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.$cookies.set("popupModalRegister", true);
            this.$router.push("/profile");
          } else {
            this.disableBtn = false;
            if (data.detail[0]) {
              this.successMessage = data.detail[0];
            } else {
              this.successMessage = data.message;
            }
            this.$refs.modalError.show();
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.content-page {
  margin-top: 80px;
  overflow: scroll;
  min-height: 100vh;
}
</style>
